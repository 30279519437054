// FBB02E laranja

import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams, useSearchParams } from "react-router-dom";

// 272B30 PRETO
function App() {

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString);

  const [videoId, setVideoId] = useState(null)

  useEffect(()=> {
    setVideoId(urlParams.get('video_id'))
    document.title = 'My replay Cam';
  }, [])

  const handleDownload = () => {
    let url = `https://api.filmo.club/api/videos/${videoId}/blob`
    let fileName = 'MyReplay.mov'
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  return (
    <div style={{backgroundColor:'#272B30', display:'flex', flex: 1, height: '100vh'}}>
      <div style={{backgroundColor:'#272B30', display:'flex', flexDirection:'column', justifyContent: 'space-between', flex: 1, height: '90vh'}}>
        <div style={{backgroundColor: '#FBB02E', height: 50, width:'100vw', display: 'flex', alignItems:'center', justifyContent:'center'}}>
          <span style={{ color: '#fff', fontSize:19, fontWeight:700 }}>COURT VIDEO</span>
        </div>

        <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding: 5, width: '100vw'}}>
          {videoId &&
            <ReactPlayer 
              url={`https://api.filmo.club/api/videos/${videoId}/blob`}
              playing={false}
              controls={true}
              download={false}
              style={{width: '100vw'}}
            />
          }
        </div>

        <div style={{backgroundColor: '#FBB02E', height: 50, width:'100vw', display: 'flex', alignItems:'center', justifyContent:'center', top:'150px'}}>
        <button style={{backgroundColor: '#FBB02E', height: 50, width:'100vw', display: 'flex', alignItems:'center', justifyContent:'center', color: '#fff', fontSize:19, fontWeight:700}} type="primary" onClick={handleDownload}>
          Download
        </button>
        </div>

      </div>
    </div>
  );
}

export default App;
